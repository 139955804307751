import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Container, SEO, BlogList } from "../components"
import { ExternalLink } from "../components/shared"
import dp from "../../content/images/displayPicSmall.jpg"
import { recommendations } from "../lists"

const Home = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query LatestBlogsQuery {
        allMarkdownRemark(
          limit: 8
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { type: { eq: "post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 180)
              id
              frontmatter {
                title
                date
                path
                type
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 700, quality: 100) {
                      ...GatsbyImageSharpFluid
                      presentationWidth
                    }
                  }
                }
              }
              fields {
                readingTime {
                  text
                }
              }
            }
          }
        }
      }
    `
  )
  return (
    <Container title="Home">
      <SEO />
      <Link to="/about">
        <div className="aboutMe">
          <div className="row">
            <div className="column thirty right">
              <img src={dp} className="dp" alt="Krishna Prasanth Vemuganti" />
            </div>
            <div className="column seventy left">
              <h2>
                <strong>Krishna Prasanth Vemuganti</strong> &#9996;
              </h2>
            </div>
          </div>
        </div>
      </Link>
      <div className="subHeading">
        <h2>Latest Posts</h2>{" "}
        <span className="links">
          <Link to="/blog">View All</Link>
        </span>
        <div className="line" />
      </div>
      <BlogList posts={allMarkdownRemark.edges} />
      <section className="marginTopSixty">
        <div className="subHeading">
          <h2>Good Reads</h2>
          <div className="line" />
        </div>
        <ul>
          {recommendations
            .filter((item) => item.show === true)
            .map((item) => (
              <li key={item.name}>
                <i>{item.type}</i> -{" "}
                <ExternalLink Link={item.link} Name={item.name} />
              </li>
            ))}
        </ul>
      </section>
    </Container>
  )
}

export default Home
